<template>
  <div>
    <h2>欢迎进入 中外运普菲斯</h2>
  </div>
</template>

<script>
  import $API from '@api'

  export default {
    name: 'Dashboard',
    components: {},
    data() {
      return {
        desserts:{}
      }
    },
    computed: {},
    created() {
    },
    methods:{
    }
  }
</script>
